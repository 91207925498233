<template>
  <div class="container p-0">
    <h1 class="text-center rounded-pill py-2 bg-light mt-2 mb-3 p-0">浪勁咖啡 - 訂單列表</h1>
    <div class="row justify-content-center">
      <div class="col-md-8">
        <ul class="nav justify-content-center nav-pills nav-fill">
          <li class="nav-item me-4">
            <div class="alert alert-secondary rounded-pill" role="alert" @click="getOrders('ORDER')"><span class="fw-bold">待付款</span></div>
          </li>
          <li class="nav-item me-4">
            <div class="alert alert-secondary rounded-pill" role="alert" @click="getOrders('PAID')"><span class="fw-bold">待出貨</span></div>
          </li>
          <li class="nav-item">
            <div class="alert alert-secondary rounded-pill" role="alert" @click="getOrders('DELIVERED')"><span class="fw-bold">已出貨</span></div>
          </li>
        </ul>
      </div>
      <div class="col">
        <table class="table table-striped">
          <thead>
            <tr class="bg-dark text-white">
              <th scope="col">訂單編號</th>
              <th scope="col">聯絡資訊</th>
              <th scope="col">收件地址</th>
              <th scope="col">訂單內容</th>
              <th scope="col">訂單金額</th>
              <th v-if="current_status == 'ORDER'" scope="col">匯款後五碼</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in userOrders" :key="order.order_id">
              <th scope="row">{{ order.order_id }}</th>
              <td>
                {{ order.receiver_name }}<br />
                {{ order.receiver_phone }}
              </td>
              <td>
                {{ order.ship_store }}<br />
                門市編號：{{ order.store_number }}
              </td>
              <td>{{ getOrderDetail(order.purchased_item) }}</td>
              <td>{{ order.order_sum }}</td>
              <td v-if="order.order_status == 'ORDER' && order.paid_five_number == ''">
                <div class="input-group">
                  <input type="text" class="form-control" :id="`order${order.order_id}`" aria-label="浪勁咖啡豆訂單匯款後五碼" />
                  <button type="button" class="btn btn-success" :aria-describedby="`order${order.order_id}`" @click="fillFiveNumber(order.order_id)">確定</button>
                </div>
              </td>
              <td v-else-if="order.order_status == 'order' && order.paid_five_number != ''">{{ order.paid_five_number }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { getProduct } from "@/global";
const userOrders = ref([]);
const current_status = ref(null);

function getOrders(status) {
  userOrders.value = allOrders.value.filter((item) => item.order_status == status);
  current_status.value = status;
  console.log(userOrders.value);
}

function fillFiveNumber(orderId) {
  let fiveNum = document.getElementById("order" + orderId).value;
  allOrders.value.forEach((elem, idx, arr) => {
    if (elem.order_id == orderId) {
      arr[idx].paid_five_number = fiveNum;
    }
  });
}

function getOrderDetail(orderItems) {
  let detailStr = ``;
  if (Object.keys(orderItems).length) {
    for (const [key, value] of Object.entries(orderItems)) {
      let product = getProduct(key);
      detailStr += value + " x " + product.p_ch_name + "\n";
    }
  }
  return detailStr;
}

onMounted(() => {
  getOrders("ORDER");
  current_status.value = "ORDER";
});

const allOrders = ref([
  {
    order_id: "111",
    order_status: "ORDER",
    timeStamp: new Date(),
    receiver_name: "張沙拉",
    receiver_phone: "0979011677",
    ship_store: "全家便利商店",
    store_number: "111",
    order_memo: "",
    purchased_item: { 4: 2, 13: 1 },
    order_sum: 999,
    paid_five_number: "",
  },
  {
    order_id: "112",
    order_status: "ORDER",
    timeStamp: new Date(),
    receiver_name: "張沙拉",
    receiver_phone: "0979011677",
    ship_store: "全家便利商店",
    store_number: "112",
    order_memo: "",
    purchased_item: { 2: 2, 10: 1 },
    order_sum: 999,
    paid_five_number: "",
  },
  {
    order_id: "113",
    order_status: "PAID",
    timeStamp: new Date(),
    receiver_name: "張沙拉",
    receiver_phone: "0979011677",
    ship_store: "全家便利商店",
    store_number: "112",
    order_memo: "",
    purchased_item: { 4: 2, 5: 1 },
    order_sum: 999,
    paid_five_number: "",
  },
  {
    order_id: "114",
    order_status: "DELIVERED",
    timeStamp: new Date(),
    receiver_name: "張沙拉",
    receiver_phone: "0979011677",
    ship_store: "全家便利商店",
    store_number: "112",
    order_memo: "",
    purchased_item: { 1: 1, 2: 1 },
    order_sum: 999,
    paid_five_number: "",
  },
]);
</script>
<style scoped>
.alert {
  cursor: pointer;
}
.alert:hover {
  background-color: rgba(221, 221, 221, 0.904);
}
td {
  white-space: pre;
}
</style>
