<template>
  <div class="container p-0">
    <div class="row mt-5 justify-content-center">
      <div class="col-md-8">
        <form action="">
          <div class="input-group mb-3">
            <span class="input-group-text" id="inputGroup-sizing-default">訂購姓名</span>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" />
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text" id="inputGroup-sizing-default">電話號碼</span>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" />
          </div>
          <div class="row justify-content-center">
            <div class="col-md-6"><button type="button" class="btn btn-light w-100">清除資料</button></div>
            <div class="col-md-6">
              <button type="button" class="btn btn-dark w-100"><a href="/orders" class="text-decoration-none text-white">查詢訂單</a></button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-8 my-4 opacity-50">
        <img class="w-100" src="https://www.linkpicture.com/q/LPic63fc961cc761814709919.jpg" type="image" />
      </div>
    </div>
  </div>
</template>
